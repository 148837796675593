<template>
  <div class="Importcenter">
    <Header back="返回" title="数据导入" index="首页" beforeTitle="数据导入" />
    <div class="content">
      <div class="ImportTop">
        <el-select v-model="type" placeholder="请选择" @change="changeType">
          <el-option
            :label="item.label"
            :value="item.value"
            v-for="item in options"
            :key="item.value"
          ></el-option>
          <!-- <el-option label="合作客户" value="partner">合作客户</el-option>
					<el-option label="意向客户" value="potential">意向客户</el-option>
					<el-option label="专利" value="patent">专利</el-option>
          <el-option label="软著" value="soft">软著</el-option>-->
        </el-select>
        <el-upload
          class="upload-demo"
          v-model="file_name"
          ref="imgupload"
          action="string"
          :http-request="httpRequest"
          :show-file-list="false"
          :before-upload="beforeAvatarUpload"
          :file-list="fileList"
        >
          <el-button type="primary" class="ChooseFile">请选择文件上传</el-button>
        </el-upload>
        <h5
          style="
            display: inline-block;
            color: #a6abc7;
            margin-left: 20px;
            font-size: 14px;
            font-weight: 500;
          "
          v-if="Message"
        >
          <span style="color: #0f38ff; font-weight: bold; font-size: 14px">
            {{
            Message
            }}
          </span>
          <p style="display: inline-block; font-size: 14px; margin-left: 20px" v-if="success > 0">
            已成功导入
            <span style="color: #0f38ff; font-weight: bold">{{ success }}家</span>企业
          </p>
          <p
            style="display: inline-block; font-size: 14px; margin-left: 20px"
            v-if="error_count > 0"
          >
            失败
            <span style="color: #ff2020; font-weight: bold">{{ error_count }}家</span>企业
          </p>
          <p style="display: inline-block; font-size: 14px; margin-left: 20px" v-if="error_url">
            请
            <a :href="userSite + error_url" style="color: #0f38ff; font-weight: bold">下载</a>失败数据
          </p>
        </h5>
      </div>
      <h1>文件上传的要求和规范说明</h1>
      <h2>
        上传文件标准模板：
        <a :href="userSite + Url" target="_blank">模板下载</a>
        <span>（点击下载,只支持表格Excel）</span>
      </h2>
      <h2>文件中的字段必须按照以下要求进行编辑</h2>
      <dl class="clearfix">
        <dt>
          <span>字段名称</span>必要条件
        </dt>
        <dd
          v-for="(item, name, index) in directions"
          :key="item.title"
          :label="item.title"
          :value="item.title"
        >
          <span>{{ name }}</span>
          {{ item }}
        </dd>
      </dl>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import site from "../../components/global.vue";
export default {
  data () {
    return {
      userSite: site.userSite,
      type: "partner",
      typeId: "partner",
      options: [
        { value: "partner", label: "合作客户" },
        { value: "potential", label: "意向客户" },
        { value: "patent", label: "专利" },
        { value: "soft", label: "软著" },
        { value: "check", label: "查新检测" },
      ],
      directions: [],
      Url: "",
      file_name: "",
      fileList: [],
      Message: "",
      success: "",
      error_count: "",
      error_url: "",
    };
  },
  created () {
    this.axios
      .get("/api/data/import_info", {
        params: {
          type: this.typeId,
        },
      })
      .then((res) => {
        console.log(res);
        this.directions = res.data.title;
        this.Url = res.data.url;
      });
    this.$forceUpdate();
  },
  methods: {
    changeType (val) {
      console.log(val, ':val');
      this.typeId = val;
      this.axios
        .get("/api/data/import_info", {
          params: {
            type: this.typeId,
          },
        })
        .then((res) => {
          console.log(res);
          this.directions = res.data.title;
          this.Url = res.data.url;
        });
      this.$forceUpdate();
    },

    httpRequest (param) {
      console.log(param);
      let file = param.file;
      const form = new FormData();
      form.append("file", file);
      form.append("type", this.typeId);
      console.log(form);
      this.upload("/api/data/import_data", form).then((res) => {
        console.log(res);
        this.Message = res.message;
        this.success = res.data.success;
        this.error_count = res.data.error_count;
        this.error_url = res.data.error_url;
      });
    },
    upload (url, params) {
      return axios
        .post(url, params, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          return new Promise((resolve, reject) => {
            resolve(res);
          });
        })
        .catch((err) => {
          console.log("err:", err);
        });
    },
    // 上传文件前的过滤
    beforeAvatarUpload (file) {
      const isLt20M = file.size / 1024 / 1024 < 20;
      if (!isLt20M) {
        this.$message.error("上传文件大小不能超过 20MB!");
      }
      return isLt20M;
    },

    submitUpload () {
      this.$refs.upload.submit();
    },
    handleRemove (file, fileList) {
      console.log(file, fileList);
    },
    handlePreview (file) {
      console.log(file);
    },
  },
};
</script>

<style>
.Importcenter .ImportTop {
  border-bottom: 1px solid #edeef4;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.Importcenter .upload-demo {
  display: inline-block;
}

.Importcenter .ChooseFile {
  width: 224px;
  height: 40px;
  background: #edeef4 !important;
  color: #909399 !important;
  border: none;
  margin-left: 20px;
}

.Importcenter .UploadFile {
  width: 88px;
  height: 40px;
  background: #0f38ff !important;
  color: #fff !important;
  border: none;
  margin-left: 20px;
}

.Importcenter .el-upload-list {
  display: inline-block;
}

.Importcenter .el-upload-list__item-name {
  margin-top: 20px;
}
.Importcenter .content h1 {
  font-size: 20px;
  color: #000000;
}
.Importcenter .content h2 {
  color: #000000;
  font-size: 14px;
  margin-top: 20px;
  font-weight: 400;
}
.Importcenter .content a {
  font-weight: bold;
  color: #0f38ff;
}
.Importcenter .content h2 span {
  color: #a6abc7;
}
.Importcenter .content dl {
  margin-top: 40px;
}
.Importcenter .content dl dt {
  border-bottom: 1px solid #ececec;
  padding-bottom: 20px;
  margin-bottom: 20px;

  color: #bdbdbd;
}
.Importcenter .content dl dd {
  border-bottom: 1px solid #ececec;
  padding-bottom: 20px;
  margin-bottom: 20px;
  line-height: 25px;
  margin-left: 0;
}
.Importcenter .content dl dt span {
  width: 120px;
  display: inline-block;
  margin-right: 50px;
}
.Importcenter .content dl dd span {
  width: 120px;
  display: inline-block;
  margin-right: 50px;
}
</style>
